<template>
  <div>
    <static-fullscreen-card v-show="!idItemShow">
      <template v-slot:header></template>
      <template v-slot:actions>
        <a-btn-refresh @click="$refs.table.updateData()" />
        <a-btn-add :icon="'far fa-plus-square'" :title="'Создать'" v-if="getAccess('objectClose.create')" @click="createNew()" />
      </template>
      <portal to="v-main">
        <s-document-head v-model="showEditDialog" v-if="showEditDialog" :api="url" :m="m" :config="configHead" :title="m.title" :id="idEdit" />
      </portal>
      <a-table-f-api
        ref="table"
        :api="url"
        :model="getModelList(m)"
        :useQuery="false"
        :defaults="defaults"
        :selectedRows="selectedRows"
        @click="
          counter += 1;
          onDetectClick($event);
        "
        @click1="onClickRow($event)"
      >
      </a-table-f-api>
    </static-fullscreen-card>
    <ViewItem v-if="idItemShow" :idShow="idItemShow" @close="itemShowClose($event)" />
  </div>
</template>

<script>
import { getAccess, genModel, doubleClickDetect, keyDetect } from "@/components/mixings";

export default {
  mixins: [getAccess, genModel, doubleClickDetect, keyDetect],
  components: {
    ViewItem: () => import("./views/objectCloseView"),
  },
  data() {
    return {
      idEdit: 0,
      showEditDialog: false,
      idItemShow: 0,
      title: "",
      m: this.$store.getters["config/get"].models.objectClose,

      url: "/accounting/doc/close_object",
      defaults: {
        sort: { key: "id", order: "DESC" },
        filters: this.getAccess("objectClose.viewAll") ? {} : { createdby_id: this.$root.profile.id },
        paramName: "objectClose",
      },
      infoData: null,
      infoModel: null,
    };
  },
  created() {
    this.$root.title = "Журнал закрытия объектов";
  },
  computed: {
    configHead() {
      return this.m.config.default || {};
    },
    permit() {
      return this.getAccess("menu.objectClose");
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData();
      }
    },
  },
  methods: {
    itemShowClose() {
      this.loading = true;
      this.$refs.table.updateRowData(this.idItemShow);
      this.loading = !true;
      this.idItemShow = 0;
    },

    onSingleClick(d) {},
    onDoubleClick(d) {
      this.idItemShow = d.row.id;
      return;
      this.onClickRow(d);
    },
    onClickRow(d) {
      this.showEditDialogFun(d.row.id);
    },
    itemShowClose() {
      this.loading = true;
      this.$refs.table.updateRowData(this.idItemShow);
      this.loading = !true;
      this.idItemShow = 0;
    },
    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
  },
};
</script>
